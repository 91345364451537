<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Tarih ve Fiyat Bilgisi'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Ürününüzü nasıl fiyatlandırdığınızla başlayalım.'">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12">
                  <h4 class="mt-2">Ürün aktif tarihlerini belirtin.</h4>
                </b-col>
                <b-col cols="12">
                  <b-form-group label-cols="1" label="Günler">
                    <b-form-checkbox-group v-model="form.gunler" :options="gunler" class="demo-inline-spacing" />
                  </b-form-group>
                  <b-form-group label-cols="1" label="Aylar">
                    <b-form-checkbox-group v-model="form.aylar" :options="aylar" class="demo-inline-spacing" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h4 class="mt-2">Seans Alış Saati Ekleyiniz.</h4>
                </b-col>
                <b-col cols="12">
                  <b-form-group description="Saati yazıp enter'a basın.">
                    <b-form-tags
                      v-model="form.seans_alis_saat"
                      input-id="tags-basic"
                      class="mt-2 rounded-0"
                      placeholder="12:00,14:30 vb."
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <h4 class="mt-2">Katılabilecek yaş gruplarını veya fiyat belirtin</h4>
                </b-col>
                <b-col cols="12">
                  <v-select
                    v-model="form.fiyat_politikasi"
                    :options="fiyatPolitikasi"
                    :reduce="(politika) => politika.value"
                    label="text"
                    :clearable="false"
                    style="width: 300px"
                    class="invoice-filter-select d-block pt-2"
                  />
                </b-col>
                <b-col cols="12">
                  <table class="table table-bordered my-2" v-if="form.fiyat_politikasi == 'tekil'">
                    <thead>
                      <tr>
                        <th width="25%"></th>
                        <th>En Az</th>
                        <th>En Çok</th>
                        <th>Fiyat</th>
                        <th rowspan="4">Kur</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Yetişkin</td>
                        <td><b-form-input v-model="form.tekil_fiyat.yetiskin.en_az" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.yetiskin.en_cok" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.yetiskin.fiyat" class="rounded-0" /></td>
                        <td rowspan="4">
                          <v-select
                            v-model="form.kur"
                            :options="getKurTurleri"
                            :reduce="(kur) => kur.banka_kodu"
                            label="birim"
                            :clearable="false"
                            :disabled="true"
                            style="width: 150px"
                            class="invoice-filter-select d-block"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Çocuk</td>
                        <td><b-form-input v-model="form.tekil_fiyat.cocuk.en_az" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.cocuk.en_cok" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.cocuk.fiyat" class="rounded-0" /></td>
                      </tr>
                      <tr>
                        <td>Bebek</td>
                        <td><b-form-input v-model="form.tekil_fiyat.bebek.en_az" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.bebek.en_cok" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.tekil_fiyat.bebek.fiyat" class="rounded-0" /></td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-bordered my-2" v-else>
                    <thead>
                      <tr>
                        <th>En Az</th>
                        <th>En Çok</th>
                        <th>Fiyat</th>
                        <th>Artı Kişi Fiyat</th>
                        <th rowspan="4">Kur</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b-form-input v-model="form.grup_fiyat.en_az" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.grup_fiyat.en_cok" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.grup_fiyat.fiyat" class="rounded-0" /></td>
                        <td><b-form-input v-model="form.grup_fiyat.arti_kisi_fiyat" class="rounded-0" /></td>
                        <td rowspan="4">
                          <v-select
                            v-model="form.kur"
                            :options="getKurTurleri"
                            :reduce="(kur) => kur.banka_kodu"
                            label="birim"
                            :clearable="false"
                            :disabled="true"
                            style="width: 150px"
                            class="invoice-filter-select d-block"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row class="py-2">
                <b-col cols="12">
                  <h5>Fiyata indirim uygulamak istiyormusunuz?</h5>
                </b-col>
                <b-col cols="12" md="6" class="pt-2">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button squared variant="outline-secondary" @click="form.indirim_turu = 'yuzde'">Yüzde</b-button>
                      <b-button squared variant="outline-primary" @click="form.indirim_turu = 'fiyat'">Fiyat</b-button>
                    </b-input-group-prepend>
                    <b-form-input class="rounded-0" type="number" min="0.00" v-model="form.indirim_oran" />
                    <b-input-group-append>
                      <b-input-group-text>
                        {{ form.indirim_turu == 'yuzde' ? '%' : form.kur }}
                      </b-input-group-text>
                      <b-button squared variant="outline-danger" @click="handlerIndirimTemizle"> Temizle </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>Çocuklar için en az bir yetişkinin refakati gerekiyor mu?</h5>
                </b-col>
                <b-col cols="12">
                  <div class="demo-inline-spacing pb-3">
                    <b-form-radio v-model="form.cocuk_refakat" name="some-radios" :value="true"> Evet </b-form-radio>
                    <b-form-radio v-model="form.cocuk_refakat" name="some-radios" :value="false"> Hayır </b-form-radio>
                  </div>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Kaydet ve Devam et' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block>
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
export default {
  components: {
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    indirimTuru: [
      { id: 'yuzde', value: 'Yüzde Oran Belirle' },
      { id: 'fiyat', value: 'Sabit Fiyat Belirle' },
    ],
    gunler: [
      { text: 'Pzt', value: 'pazartesi' },
      { text: 'Sal', value: 'sali' },
      { text: 'Çar', value: 'carsamba' },
      { text: 'Per', value: 'persembe' },
      { text: 'Cum', value: 'cuma' },
      { text: 'Cmt', value: 'cumartesi' },
      { text: 'Paz', value: 'pazar' },
    ],
    aylar: [
      { text: 'Oca', value: 'ocak' },
      { text: 'Şub', value: 'subat' },
      { text: 'Mar', value: 'mart' },
      { text: 'Nis', value: 'nisan' },
      { text: 'May', value: 'mayis' },
      { text: 'Haz', value: 'haziran' },
      { text: 'Tem', value: 'temmuz' },
      { text: 'Ağu', value: 'agustos' },
      { text: 'Eyl', value: 'eylul' },
      { text: 'Eki', value: 'ekim' },
      { text: 'Kas', value: 'kasim' },
      { text: 'Ara', value: 'aralik' },
    ],
    fiyatPolitikasi: [
      { text: 'Tekil Fiyatlandır', value: 'tekil' },
      { text: 'Grup Fiyatlandır', value: 'grup' },
    ],
  }),

  computed: {
    ...mapGetters(['getKurTurleri']),
  },
  async beforeMount() {
    await this.$store.dispatch('digerAyarlariGetir');
    const kur = this.$store.getters.getDigerAyarlar.varsayilan_kur;
    if (this.form.kur == null) {
      this.form.kur = kur;
    }
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit');
    },
    handlerIndirimTemizle() {
      this.form.indirim_turu = '';
      this.form.indirim_oran = 0;
    },
  },
};
</script>
<style lang="scss">
.col-form-label {
  padding-top: calc(0.438rem + 13px);
  font-weight: bold;
}
</style>
